<template>
  <div>
    <b-card
      bg-variant="light"
      header="Mis Solicitudes de Adopción"
      header-bg-variant="primary"
      header-text-variant="white"
      align="center"
    >
      <b-col cols="12" class="text-right">
        <b-button
          class="vertical-align"
          variant="secondary"
          @click="abrirModalAgregar"
        >
          <b-icon icon="plus-circle"></b-icon>
          Agregar nueva Solicitud
        </b-button>
      </b-col>

      <br /><br />

      <b-row v-if="solicitudesAdopcionServicio.length > 0">
      <b-col>
        <b-table
          id="consultaLicencias"
          striped
          responsive
          small
          bordered
          :items="solicitudesAdopcionServicio"
          :fields="camposTabla"
          :per-page="perPage"
          :current-page="currentPage"
          :tbody-tr-class="rowClass"
          select-mode="single"
          ref="selectableTable"
          selectable
          @row-selected="onRowSelected"
        >
          <template #cell(seleccionar)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row v-if="solicitudesAdopcionServicio.length > 0">
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="consultaLicencias"
        ></b-pagination>
      </b-col>
    </b-row>
    </b-card>

    <b-modal
      id="ModalAgregar"
      v-model="mostrarModalAgregar"
      hide-footer
      size="lg"
    >
      <template #modal-header>
        <h5>Agregar Mascota</h5>
        <b-button @click="cancelar(false)" variant="link"> x </b-button>
      </template>
      <div class="d-block text-center p-3">
        <solicitudAdopcion @cerrarModal="cancelar(true)" />
      </div>
    </b-modal>
  </div>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import { encontrarError } from "../../../contantesErrores";
  import solicitudAdopcion from "../../../components/animales/adopcion/SolicitudAdopcion.vue";
  import { traerNombreEstadoAdopcion } from "../../../constantes";
  import moment from "moment";
  export default {
    name: "MisAnimales",
    components: {
      solicitudAdopcion,
    },
    data() {
      return {
        mostrarModalAgregar: false,
        cerrarModal: false,
        animalSelec: {},
        persona: {},
        camposTabla: [
          "seleccionar",
          {
            key: "fecha_solicitud",
            formatter: (value) => {
              return moment(value).format("DD-MM-YYYY");
            },
          },
          "nombre_persona_solicita",
          {
            key: "iden_persona_solicita",
            label: "Identificación",
          },
          // {
          //     key: 'telefono',
          //     label: 'Teléfono'
          // },
          "desc_tipo_animal",
          "nombre_animal",
          {
            key: "estado",
            formatter: (value) => {
              let estado = traerNombreEstadoAdopcion(value);
              return estado.nombre;
            },
          },
          {
            key: "nombre_funcionario_aprueba",
            label: "Funcionario Aprueba/Rechaza"
          },
        ],
        perPage: 10,
        currentPage: 1,
      };
    },
    mounted() {
      this.resetAnimalesServicio();
      if (localStorage.getItem("persona")) {
        try {
          this.persona = JSON.parse(localStorage.getItem("persona"));
          this.cargarMisSolicitudes();
        if(this.persona.nombre_persona == ' '){
          this.modalMensaje('Debe diligenciar los datos personales para continuar.')
        }
      } catch (e) {
        localStorage.removeItem("persona");
      }
    }
    },
    computed: {
      ...mapState("animales", ["solicitudesAdopcionServicio"]),
      rows() {
        return this.solicitudesAdopcionServicio.length;
      },
    },
    methods: {
      ...mapActions("animales", ["buscarSolicitudesAdopcion", "seleccionarSolicitudAdopcion", "resetAnimalesServicio"]),

      async cargarMisSolicitudes(){
        console.log('la persona',this.persona)
        let filtros = {
          solicitudadopcionparams: {
            iden_responsable: this.persona.login,
          },
        };
        try {
          await this.buscarSolicitudesAdopcion(filtros);
        } catch (e) {
          encontrarError(this, e.data);
        }
      },

      mostrarSexo(value) {
        if (value == 2) {
          return "Hembra";
        } else {
          return "Macho";
        }
      },

      calcularEdad(value) {
        let edad = null;
        if (value != null) {
          let fechaNacimiento = moment(value, "YYYY-MM-DD");
          let fechaActual = moment();
          edad = fechaActual.diff(fechaNacimiento, "years");
        }
        return edad;
      },

      rowClass(item, type) {
        if (!item || type !== "row") return;
        return "rowspace";
      },

      onRowSelected(items) {
        if (items.length > 0) {
          this.seleccionarSolicitudAdopcion(items[0]);
          this.abrirModalAgregar()
        } else {
          this.seleccionarSolicitudAdopcion({});
        }
      },

      cancelar(guardar) {
        this.cerrarModalAgregar();
        if (guardar == true) {
          this.cargarMisSolicitudes();
          this.seleccionarSolicitudAdopcion({});
        }
      },

      cerrarModalAgregar(){
        console.log('tal vez aqui')
        this.cerrarModal=true
        this.seleccionarSolicitudAdopcion({})
        this.$bvModal.hide('ModalAgregar')
      },

      abrirModalAgregar() {
        this.mostrarModalAgregar = true;
      },
      
      modalMensaje(mensaje) {
        this.$bvModal
          .msgBoxOk(mensaje, {
            title: "Error",
            size: "md",
            buttonSize: "md",
            okVariant: "danger",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then(() => {
            this.$router.push({ name: "misDatos" });
          })
          .catch((err) => {
            console.log(err);
          });
      }, 
    },
  };
</script>